import React from 'react'
import styled from 'styled-components'
import Layout from 'components/layout'
import Content from 'components/content'
import { Helmet } from 'react-helmet'
import slugify from 'utils/slugify'
import sortObjectKeys from 'utils/sort-object-keys'
import { Link } from 'gatsby'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'

const KnowledgeCenterTagsTemplate = props => {
  const { breadcrumbs, data, tagCount } = getInitialProps(props)
  return (
    <Layout kind='white'>
      <Helmet>
        <title>Knowledge Center Tags | Hutson Inc</title>
        <meta
          name='description'
          content='Find in-depth articles and insights about features on John Deere and STIHL equipment, categorized by tag in the Hutson knowledge center.'
        />
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <StyledContent>
        <Title>Knowledge Center Tags ({tagCount})</Title>
        {Object.keys(data).map(letter => (
          <div key={`Group_${letter}`}>
            <GroupHeader>{letter.toUpperCase()}</GroupHeader>
            <List>
              {data[letter].map(({ count, tag }) => (
                <ListItem key={tag}>
                  <StyledLink to={`/knowledge-center/tags${slugify(tag)}`}>{tag}</StyledLink>
                  <Count>({count})</Count>
                </ListItem>
              ))}
            </List>
          </div>
        ))}
      </StyledContent>
    </Layout>
  )
}

const StyledContent = styled(Content)`
  @media (min-width: 800px) {
    max-width: 800px;
  }
`

const Title = styled.h1`
  font-size: 2em;
`

const GroupHeader = styled.h2`
  border-bottom: 1px solid #d7d7d7;
  margin: 30px 0 10px;
  padding: 0 0 5px 0;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  display: inline-block;
  list-style: none;
  margin: 10px 15px;
  padding: 0;
`

const StyledLink = styled(Link)`
  color: #377539;
  text-decoration: none;

  :hover,
  :focus {
    text-decoration: underline;
  }
`

const Count = styled.span`
  font-size: 0.9em;
  font-weight: 300;
  margin-left: 5px;
`

const getInitialProps = ({ pageContext: { allTags: tags } }) => {
  const breadcrumbs = [
    {
      name: 'Home',
      schemaName: 'Hutson Inc',
      link: '/',
    },
    {
      name: 'Knowledge Center',
      link: '/knowledge-center/',
    },
    {
      name: 'Tags',
      link: '/knowledge-center/tags/',
    },
  ]

  const tagCount = tags.length

  const letterGroups = tags.reduce((acc, obj) => {
    const { tag } = obj
    const letterGroup = tag.toLowerCase().substr(0, 1)

    if (acc[letterGroup]) {
      acc[letterGroup].push(obj)
    } else {
      acc[letterGroup] = [obj]
    }

    return acc
  }, {})

  const letterGroupsKeys = Object.keys(letterGroups)

  letterGroupsKeys.forEach(key => {
    letterGroups[key].sort((a, b) => {
      a = a.tag.toLowerCase()
      b = b.tag.toLowerCase()
      if (a < b) return -1
      if (a > b) return 1
      return 0
    })
  })

  const letterGroupsSorted = sortObjectKeys(letterGroups)

  return {
    breadcrumbs,
    data: letterGroupsSorted,
    tagCount,
  }
}

export default KnowledgeCenterTagsTemplate
