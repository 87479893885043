/**
 * Sort the keys of an object
 * @param {Object} obj
 * @example
 * sortObjectKeys({b: 2, a: 1})
 * // returns {a: 1, b: 2}
 * @returns {Object}
 */
const sortObjectKeys = obj => {
  const ordered = {}
  Object.keys(obj)
    .sort((a, b) => {
      a = a.toLowerCase()
      b = b.toLowerCase()
      if (a < b) return -1
      if (a > b) return 1
      return null
    })
    .forEach(key => {
      ordered[key] = obj[key]
    })
  return ordered
}

module.exports = sortObjectKeys
