/**
 * Convert a string to a proper slug
 * @param {string} str - String to slugify
 * @returns {string} Slugified string
 */
const slugify = str => {
  const slug = str
    .toLowerCase()
    .replace(/[\W_]+/g, '-')
    .replace(/(^-+|-+$)/g, '')

  return `/${slug}`.replace(/\/\/+/g, '/')
}

module.exports = slugify
